<style scoped>
/* 视图盒子 */
.view-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

/* EAEFF3 */
.bg-1 {
  height: 100%;
  background: url("./../com-view/bg.jpg") no-repeat center;
}

.bg-2 {
  height: 50%;
  background-color: #eaeff3;
}

.login-box {
  width: 370px;
  height: 370px;
  position: absolute;
  left: calc(50% - 185px);
  top: calc(50% - 270px);
}

/* .login-box{} */

/* logo */
.login-top {
  margin-bottom: 40px;
  text-align: center;
}

.logo-img {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  border-radius: 50%;
  margin-left: -10px;
  margin-right: 20px;
}

.logo-img {
  position: relative;
  top: -5px;
}

.admin-title {
  font-size: 28px;
  color: #fff;
  font-weight: 700;
}

/* 表单 */
.from-box {
  padding: 20px 40px;
  background-color: #fff;
}

.from-box {
  border-radius: 20px;
  box-shadow: 1px 1px 2px #666;
}

.from-title {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}

/deep/ .verify-bar-area {
  background: #eeeeee;
}

/deep/ .el-input__inner {
  border-radius: 15px;
}
</style>
<template>
  <div class="view-box" v-if="isShow">
    <div class="bg-1"></div>
    <div class="bg-2"></div>
    <div class="login-box">
      <div class="login-box-2">
        <div class="login-top">
          <img src="./../index/admin-logo.png" class="logo-img" />
          <span class="admin-title">NANPay</span>
        </div>
        <div class="from-box">
          <h3 class="from-title">NANPay 商户管理系统</h3>
          <el-form size="small" label-position="left" label-width="0px">
            <el-form-item>
              <el-input prefix-icon="el-icon-user" v-model="m.username" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input prefix-icon="el-icon-lock" v-model="m.password" type="password" placeholder="请输入密码"
                        @keyup.native.enter="ok()"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input prefix-icon="el-icon-mobile-phone" v-model="m.code" type="code"
                        placeholder="Google验证码,未设置请忽略" @keyup.native.enter="ok()"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button round type="primary" size="small" style="width: 100%" @click="ok()">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 底部 版权 -->
    <div style="
        position: absolute;
        bottom: 40px;
        width: 100%;
        text-align: center;
        color: rgb(255 255 255);
      ">
      Copyright ©2024 NANPay 商户管理系统
    </div>
  </div>
</template>

<script>
export default {
  name: 'sa-login',
  data () {
    return {
      isShow: false, // 是否显示当前视图
      m: {
        username: '',
        password: '',
        code: '',
      },
      AUTH_HEADER_KEY: 'Authorization',
      TOKEN_PREFIX: 'Bearer ',
    }
  },
  methods: {
    // 点击确定
    ok () {
      // 表单验证
      if (this.m.username == '' || this.m.password == '') {
        return this.sa.error2('请输入完整的账号密码')
      }
      // 开始登录
      this.sa.ajax(
        '/v1/index/login',
        this.m,
        function (res) {
          var defaultCfg = {
            type: 'get', //是否是请求体请求
          }
          this.sa.setCookie(this.AUTH_HEADER_KEY, this.TOKEN_PREFIX + res.data, 0)
          this.sa.ajax(
            '/getMerchantRole',
            function (res) {
              this.sa.setCookie('SYS_ROLE', res.data.roles, 0)
              this.sa.setCookie('SYS_MCHID', res.data.mchId, 0)
              this.sa.setCookie('SYS_NICKNAME', res.data.nickName, 0)
              location.reload()
            }.bind(this),
            defaultCfg,
          )
        }.bind(this),
      )
    },
  },
}
</script>

