/* eslint-disable */

// 一个菜单可以包括的所有属性
// {
// 	id: '12345',		// 菜单id, 必须唯一
// 	name: '用户中心',		// 菜单名称, 同时也是tab选项卡上显示的名称
// 	icon: 'el-icon-user',	// 菜单图标, 参考地址:  https://element.eleme.cn/#/zh-CN/component/icon
// 	info: '管理所有用户',	// 菜单介绍, 在菜单预览和分配权限时会有显示
// 	view:  () => import('@/sa-view/user/user-list.vue'),	// 指向的视图
//	params: {},					// 视图参数
// 	url: 'sa-html/user/user-list.html',	// 菜单指向地址, 用iframe打开它 (配置后,view属性失效)
// 	is_blank: false,		// 如果指定了url, 此属性决定是否从新窗口打开
// 	is_show: true,			// 是否显示, 默认true
// 	childList: [			// 指定这个菜单所有的子菜单, 子菜单可以继续指定子菜单, 至多支持三级菜单
// 		// ....
// 	],
// 	click: function(sa_admin, sa) {		// 配置一个函数,  点击菜单时, 会执行这个函数
// 		console.log(sa);
// 	}
// }

/* 菜单集合 */
export default [
  {
    id: 'home',
    name: '控制台',
    icon: 'el-icon-s-home',
    info: '数据监控',
    view: () => import('@/sa-resources/com-view/sa-home.vue'),
  },
  {
    id: 'order',
    name: '订单管理',
    icon: 'el-icon-s-order',
    info: '对系统角色权限的分配等设计，敏感度较高，请谨慎授权',
    childList: [
      {
        id: 'order-list',
        name: '商户订单管理',
        view: () => import('@/sa-view/order/order-list.vue'),
      },
      {
        id: 'merchant-notice',
        name: '订单通知管理',
        view: () => import('@/sa-view/order/merchant-notice.vue'),
      },
    ],
  },
  {
    id: 'user',
    name: '用户管理',
    icon: 'el-icon-user',
    info: '对用户列表、添加、统计等等...',
    childList: [
      {
        id: 'user-2',
        name: '用户列表',
        view: () => import('@/sa-view/user/user-list.vue'),
      },
      {
        id: 'user-1',
        name: '用户添加',
        view: () => import('@/sa-view/user/user-add.vue'),
      },
    ],
  },
  {
    id: 'code-mch',
    name: '商户管理',
    icon: 'el-icon-bank-card',
    info: '对用户列表、添加、统计等等...',
    childList: [
      {
        id: 'wx-code-mch',
        name: '微信商户列表',
        view: () => import('@/sa-view/code-mch/wx-code-mch-list.vue'),
      },
      {
        id: 'zft-code-mch',
        name: '直付通商户列表',
        view: () => import('@/sa-view/code-mch/zft-code-mch-list.vue'),
      },
      {
        id: 'dmf-code-mch',
        name: '当面付商户列表',
        is_show: false,
        view: () => import('@/sa-view/code-mch/dmf-code-mch-list.vue'),
      },
      {
        id: 'zfb-wap-mch',
        name: '支付宝商城商户',
        is_show: false,
        view: () => import('@/sa-view/code-mch/zfb-wap-mch-list.vue'),
      },
      {
        id: 'zfb-game-mch',
        name: '支付宝游戏商户',
        is_show: false,
        view: () => import('@/sa-view/code-mch/zfb-game-mch-list.vue'),
      },
      {
        id: 'zfb-xqd-mch',
        name: '支付宝个码配置',
        is_show: false,
        view: () => import('@/sa-view/code-mch/zfb-xqd-mch-list.vue'),
      },
      {
        id: 'zfb-pre',
        name: '支付宝收款配置',
        is_show: true,
        view: () => import('@/sa-view/code-mch/zfb-pre-list.vue'),
      },
      {
        id: 'zfb-group',
        name: '支付宝分组',
        is_show: true,
        view: () => import('@/sa-view/ali-group/index.vue'),
      },
      {
        id: 'yh-code-mch',
        name: '悦浮账号列表',
        is_show: false,
        view: () => import('@/sa-view/code-mch/yf-code-mch-list.vue'),
      },
      {
        id: 'person-config',
        name: '收款码配置',
        view: () => import('@/sa-view/code-mch/person-config.vue'),
      },
      {
        id: 'custom-code-mch',
        name: '自定义通道',
        is_show: true,
        view: () => import('@/sa-view/code-mch/custom-code-mch-list.vue'),
      },
      {
        id: 'custom-code-acconut',
        name: '自定义收款账号',
        is_show: true,
        view: () => import('@/sa-view/code-mch/custom-account-list.vue'),
      },
    ],
  },
  {
    id: 'shared',
    name: '分账管理',
    icon: 'el-icon-s-promotion',
    info: '对用户列表、添加、统计等等...',
    childList: [
      {
        id: 'wx-shared-menu',
        name: '微信分账',
        childList: [
          {
            id: 'wx-shared-account',
            name: '分账用户',
            view: () => import('@/sa-view/share-order/share-account-list.vue'),
          },
          {
            id: 'wx-shared-order',
            name: '微信分账订单',
            view: () => import('@/sa-view/share-order/share-order-list.vue'),
          },
          {
            id: 'wx-shared-account-add',
            name: '微信分账用户添加',
            view: () => import('@/sa-view/share-order/share-account-add.vue'),
          },
        ],
      },
      {
        id: 'alipay-shared-menu',
        name: '支付宝分账',
        childList: [
          {
            id: 'ali-shared-1',
            name: '支付宝分账用户',
            view: () =>
              import('@/sa-view/ali-share-order/share-account-list.vue'),
          },
          {
            id: 'ali-shared-2',
            name: '支付宝分账订单',
            view: () =>
              import('@/sa-view/ali-share-order/share-order-list.vue'),
          },
        ],
      },
    ],

    // let template = '<div class="iframe-view-box"><iframe class="iframe-view" src="' + menu.url + '"></iframe></div>';
    // menu.view = ()=> ({template: template});
  },
  {
    id: 'statistic',
    name: '数据统计',
    icon: 'el-icon-data-analysis',
    info: '订单的数据统计管理',
    is_user: true,
    childList: [
      {
        id: 'transaction',
        name: '流水报表',
        view: () => import('@/sa-view/statistic/transaction.vue'),
        is_show: false, // 隐藏
      },
      {
        id: 'channel',
        name: '通道统计',
        view: () => import('@/sa-view/statistic/channel.vue'),
        is_show: false, // 隐藏
      },
      {
        id: 'chanel-statistic',
        name: '金额统计',
        icon: 'el-icon-data-analysis',
        info: '通道金额统计',
        is_user: true,
        view: () => import('@/sa-view/statistic/mch-price.vue'),
      },
    ],
  },
  {
    id: 'server',
    name: '电商通进件',
    icon: 'el-icon-document-copy',
    is_show: false, // 隐藏
    info: '进件管理',
    childList: [
      {
        id: 'applyment',
        name: '申请进件',
        view: () => import('@/sa-view/applyment/applyment-add.vue'),
      },
      {
        id: 'applyment-list',
        name: '进件列表',
        view: () => import('@/sa-view/applyment/applyment-list.vue'),
      },
    ],
  },
  {
    id: 'dev-center',
    name: '开发配置',
    icon: 'el-icon-document-copy',
    info: '进件管理',
    is_user: true,
    childList: [
      {
        id: 'dev-setting',
        icon: 'el-icon-menu',
        name: '开发设置',
        view: () => import('@/sa-view/dev-center/dev-setting.vue'),
        is_show: true, // 隐藏
      },
      {
        id: 'dev-doc',
        name: '开发文档',
        url: 'http://payment.gxyykj.net/api.html',
        is_show: true, // 隐藏
      },
    ],
  },
  {
    id: 'ali-server',
    name: '直付通进件',
    icon: 'el-icon-document-copy',
    info: '进件管理',
    is_show: true, // 隐藏
    childList: [
      {
        id: 'ali-applyment',
        name: '申请进件',
        view: () => import('@/sa-view/ali-applyment/ali-applyment-add.vue'),
      },
      {
        id: 'ali-applyment-list',
        name: '进件列表',
        view: () => import('@/sa-view/ali-applyment/ali-applyment-list.vue'),
      },
    ],
  },
  {
    id: 'ali-f2f-server',
    name: '当面付进件',
    icon: 'el-icon-document-copy',
    info: '进件管理',
    is_show: true, // 隐藏
    childList: [
      {
        id: 'ali-f2f-applyment',
        name: '申请进件',
        view: () => import('@/sa-view/ali-f2f-applyment/ali-applyment-add.vue'),
      },
      {
        id: 'ali-f2f-applyment-list',
        name: '进件列表',
        view: () => import('@/sa-view/ali-f2f-applyment/ali-applyment-list.vue'),
      },
    ],
  },
  //  ========= 示例 指定一个函数, 点击菜单时执行这个函数 ================
  {
    id: 'sys-config',
    name: '系统管理',
    icon: 'el-icon-setting',
    childList: [
      {
        id: 'server-provider-config',
        name: '服务商管理',
        icon: 'el-icon-document-copy',
        info: '进件管理',
        childList: [
          {
            id: 'sys-config-1',
            name: '电商通管理',
            is_show: true,
            view: () => import('@/sa-view/server-provider/server-list.vue'),
          },
          {
            id: 'sys-config-4',
            name: '直付通管理',
            view: () =>
              import('@/sa-view/server-provider/alipay-server-list.vue'),
          },
          {
            id: 'sys-config-6',
            name: '当面付管理',
            view: () =>
              import('@/sa-view/server-provider/alipay-f2f-server-list.vue'),
          },
        ],
      },
      {
        id: 'officail-provider-config',
        name: '公众号管理',
        icon: 'el-icon-document-copy',
        info: '进件管理',
        childList: [
          {
            id: 'sys-config-2',
            name: '公众号管理',
            view: () => import('@/sa-view/official/officail-account-list.vue'),
          },
          {
            id: 'sys-config-5',
            name: '电商通公众号管理',
            is_show: true,
            view: () =>
              import('@/sa-view/official/dst-officail-account-list.vue'),
          },
        ],
      },
      {
        id: 'ali-transfer',
        name: '支付宝API转账',
        is_show: true, // 隐藏
        childList: [
          {
            id: 'transfer',
            name: 'API转账',
            view: () => import('@/sa-view/ali-transfer/transfer.vue'),
          },
          {
            id: 'ali-transfer-log',
            name: 'API转账记录',
            view: () => import('@/sa-view/log-management/transfer-log.vue'),
          },
        ],
      },
      {
        id: 'channel-mg',
        name: '通道管理',
        view: () => import('@/sa-view/cfg/channel-mg.vue'),
      },
      {
        id: 'security-setting',
        name: '账号安全设置',
        is_show: true,
        childList: [
          {
            id: 'google-security',
            name: '登录二次验证',
            view: () => import('@/sa-view/google/google-security.vue'),
          },
          {
            id: 'security',
            name: '系统操作密码',
            view: () => import('@/sa-view/cfg/system-cfg.vue'),
          },
        ],
      },
      {
        id: 'log-config',
        name: '日志管理',
        icon: 'el-icon-document-copy',
        info: '进件管理',
        childList: [
          {
            id: 'login-log',
            name: '登录日志',
            is_show: true,
            view: () => import('@/sa-view/log-management/login-log.vue'),
          },
          {
            id: 'sys-opt-log',
            name: '操作日志',
            is_show: true,
            view: () => import('@/sa-view/log-management/sys-opt-log.vue'),
          },
        ],
      },
    ],
  },
  {
    id: 'block-config',
    icon: 'el-icon-s-release',
    name: '黑名单管理',
    view: () => import('@/sa-view/cfg/block-config.vue'),
    is_show: true, // 隐藏
  },
  {
    id: 'SifangPayment',
    icon: 'el-icon-menu',
    name: '支付网关入口',
    view: () => import('@/sa-view/sifang-payment/setting.vue'),
    is_show: true, // 隐藏
  },
  {
    id: 'VideoPayment',
    icon: 'el-icon-menu',
    name: 'v3支付网关',
    view: () => import('@/sa-view/sifang-payment/video-setting.vue'),
    is_show: false, // 隐藏
  },
  {
    id: 'MHPayment',
    icon: 'el-icon-menu',
    name: 'v4支付网关',
    view: () => import('@/sa-view/sifang-payment/mh-setting.vue'),
    is_show: false, // 隐藏
  },
  //  ========= 示例 外部链接 点击从新窗口打开 ================
  {
    id: '9',
    name: '一个隐藏菜单',
    view: () => import('@/sa-view/role/role-list.vue'),
    is_show: false, // 隐藏
  },
]
